import React, { useState } from "react"
import Slider from "react-slick"
import { MessageSquare, Video, SearchIcon } from "lucide-react"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./AiIntegrations.css"
import AiSliderComponent from "../AiSliderComponent"

export default function AiIntegrations() {
  const [currentSlide, setCurrentSlide] = useState(0)

  const items = [
    {
      number: "01",
      title: "AI/ML Models Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-ai-ml-models-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),

      description:
        "Leverage powerful machine learning models to enhance your product's decision-making capabilities, enabling predictive analytics, automated insights, and intelligent recommendations that evolve over time.",
    },
    {
      number: "02",
      title: "NLP Solutions Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-nlp-solutions-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Integrate advanced Natural Language Processing (NLP) to enable your product to understand, process, and generate human language. Improve features like sentiment analysis, language translation, text summarization, and automatic content tagging.",
    },
    {
      number: "03",
      title: "ChatGPT/GEMINI/ANTHROPIC Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-chatgpt-gemini-anthropic-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Harness the power of sophisticated conversational AI models like ChatGPT and BARD to elevate customer interactions, from support chats to personalized content creation, making your product more engaging and human-like.",
    },
    {
      number: "04",
      title: "Chatbot Integrations",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-chatbot-integrations"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Enhance your product with intelligent, AI-powered chatbots for automating customer support, providing 24/7 assistance, answering queries, and streamlining communication—all without human intervention.",
    },
    {
      number: "05",
      title: "Computer Vision Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-computer-vision-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Implement AI-powered computer vision to enable your product to process, analyze, and interpret visual data. Perfect for applications in security, retail, healthcare, and more—whether it's facial recognition, object detection, or image classification.",
    },
    {
      number: "06",
      title: "Speech Recognition Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-speech-recognition-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Add voice-based capabilities to your product with AI-driven speech recognition. Improve user experience with voice commands, transcription services, and hands-free control, making your product more accessible and user-friendly.",
    },
    {
      number: "07",
      title: "Predictive Analytics Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-predictive-analytics-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Integrate AI-driven predictive analytics into your product to anticipate trends, behaviors, and needs. Unlock valuable insights to improve decision-making, personalize user experiences, and optimize business operations.",
    },
    {
      number: "08",
      title: "Recommendation System Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-recommendation-system-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Enhance your product with personalized recommendation engines powered by AI, offering targeted suggestions based on user behavior, preferences, and past interactions, whether it’s for e-commerce, media, or content platforms.",
    },

    {
      number: "09",
      title: "Data Visualization with AI Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-data-visualization-with-ai-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Integrate AI-powered data visualization tools that analyze complex datasets and generate easy-to-understand charts, graphs, and insights to support strategic decision-making.",
    },
    {
      number: "10",
      title: "Fraud Detection Integration",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-fraud-detection-integration"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Use AI to identify and mitigate fraudulent activities by integrating real-time fraud detection models into your product. Perfect for finance, e-commerce, and security sectors to protect users and ensure trust.",
    },
    {
      number: "11",
      title: "Automation of Customer Insights",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-automation-of-customer-insights"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Integrate AI systems that automatically analyze customer feedback and behavior to generate actionable insights, helping you continuously optimize your product and marketing strategies.",
    },
    {
      number: "12",
      title: "AI-Powered Search",
      icon: (
        <i
          className="icon--hire-expert-ai-developers-ai-powered-search"
          style={{
            fontSize: "var(--icon-size)",
            width: "var(--icon-size)",
            height: "var(--icon-size)",
          }}
        />
      ),
      description:
        "Integrate AI-powered intelligent search engines that not only offer better search results but also provide personalized recommendations to users based on their preferences and browsing history.",
    },
  ]

  const sliderRef = React.useRef(null)

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
    beforeChange: (current, next) => setCurrentSlide(next),
  }

  return (
    <div className="relative w-full bg-[#F0F4FF] overflow-hidden">
      <div
        className="absolute inset-0 bg-cover bg-center"
        // style={{
        //   backgroundImage: `url("https://webmobtechcdn.nyc3.cdn.digitaloceanspaces.com/wmt_v4/2024/11/Our-AI-Approach.webp")`,
        // }}
      />
      <div className="relative max-w-screen-2xl mx-auto px-4 py-16">
        <div className="text-center mb-16">
          <h1
            className="text-2xl md:text-5xl font-bold text-[rgba(36, 38, 43, 1)] px-6 md:px-10 py-1 md:py-2 inline-block"
            style={{
              borderRadius: "40px",
              border: "6px solid rgba(255, 249, 249, 0.08)",
              background: "rgba(255, 255, 255, 0.16)",
            }}
          >
            AI Integrations Tailored to Enhance Your Existing Product{" "}
          </h1>
        </div>

        <p className="text-center text-lg font-medium mb-12 max-w-3xl mx-auto text-[rgba(36, 38, 43, 1)]">
          We specialize in seamlessly integrating advanced AI technologies into
          your existing products, transforming them with cutting-edge
          capabilities. Whether you’re aiming to optimize user experience,
          automate processes, or unlock insights from data, our AI developers
          tailor each solution to meet your specific needs, ensuring your
          product stays ahead of the competition.
        </p>

        <div className="relative md:px-8 md:py-12">
          <Slider ref={sliderRef} {...settings} className="gap-slider">
            {items.map((item, index) => (
              <AiSliderComponent
                key={index}
                number={item.number}
                title={item.title}
                icon={item.icon}
                description={item.description}
              />
            ))}
          </Slider>

          <div className="flex justify-center items-center gap-4 mt-8">
            <button
              onClick={() => sliderRef.current?.slickPrev()}
              className="w-12 h-12 rounded-full border  bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{border: '1px solid #335FFF'}}

            >
              <i
                className={`icon-slider-left-arrow  flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>

            <span className=" text-lg font-bold" style={{ color: "#335FFF" }}>
              {currentSlide + 1} /{" "}
              <span
                className=" text-lg font-bold opacity-50 "
                style={{ color: "#335FFF" }}
              >
                {items.length}
              </span>
            </span>
            <button
              type="button"
              onClick={() => sliderRef.current?.slickNext()}
              className="w-12 h-12 rounded-full border border-[#335FFF] bg-transparent flex items-center justify-center hover:bg-white/10 transition-colors"
              style={{border: '1px solid #335FFF'}}
            >
              <i
                className={`icon-slider-right-arrow  flex justify-center py-3 mx-0 active:text-blue-500`}
                style={{ fontSize: "20px", color: "#335FFF" }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
