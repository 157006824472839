import React from "react";
import "./AiSlideromponent.css"

export default function AiSliderComponent({ number, title, icon, description }) {
  return (
    <div className="AIcard relative  overflow-hidden" style={{backgroundColor: "white"}}>
      {/* Large Number in Background */}
      <div
        className="absolute top-4 right-6 text-7xl md:text-8xl lg:text-9xl font-bold select-none"
        style={{
          color: "#335FFF",
          opacity: 0.1,
        }}
      >
        {number}
      </div>

      {/* Card Content */}
      <div className="flex flex-col items-start h-full relative z-10">
        <div
          className="w-16 h-16 md:w-20 md:h-20 lg:w-24 lg:h-24 rounded-2xl flex items-center justify-center mb-4 md:mb-6 text-2xl md:text-3xl lg:text-4xl"
          style={{
            background: "rgba(51, 95, 255, 0.10)",
            color: "#335FFF",
          }}
        >
          {icon}
        </div>
        <h3 className="text-lg md:text-2xl lg:text-3xl font-bold text-[#3D4048] mb-3 md:mb-4 break-words w-full">
          {title}
        </h3>
        <p className="text-sm md:text-base lg:text-lg text-[#3D4048] leading-relaxed">
          {description}
        </p>
      </div>
    </div>
  );
}
